import React from 'react'
import Router from 'next/router'

import fetch from 'isomorphic-unfetch'
import styled, { keyframes, css } from 'styled-components'
import { setToken } from '../utils/auth'
import config from '../config'
import NProgress from 'nprogress'
import Head from 'next/head'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser } from '../store'
import bowser from 'bowser'
import { UserQuery, Userfragments } from "../hocs/securePage"

import { graphql, withApollo } from 'react-apollo'
import { compose } from 'recompose';
import gql from 'graphql-tag'

const LoginContainer = styled.div`
  padding-top:0px;
`
const shake = keyframes`
    8%, 41% {
        -webkit-transform: translateX(-10px);
    }
    25%, 58% {
        -webkit-transform: translateX(10px);
    }
    75% {
        -webkit-transform: translateX(-5px);
    }
    92% {
        -webkit-transform: translateX(5px);
    }
    0%, 100% {
        -webkit-transform: translateX(0);
    }
`;

const animation = css`
  ${shake} .5s linear
`;

const Input = styled.input`
  border-radius: 3px;
  margin: 0 5px;
  padding: 6px 10px;
  font-family: "Open Sans", Roboto, Helvetica;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  border-width: 1px;
  border-color: #d4d4d4;
  border-style: solid;
  -webkit-animation: ${props => props.invalid ? animation : null};
`
const Button = styled(Input.withComponent('button'))`
border-width:0;
`

const LoginButton = styled(Input)`
background-color: #103058;
border-width:0; 
border-color: #103058; 
margin-bottom:0; 
color:#fff;
`

const Form = styled.form`
${props => props.isModal ? `
display:flex;
flex-direction:column;
`: null}
& ${Input}{
  ${props => props.isModal ? `
margin-bottom:10px;
`: null}
}
margin: 15px 0;
@media only screen and (max-width: 731px){
    & ${Button}{
      margin: 7px 5px 0 !important;
    }
    @media only screen and (max-width: 619px){
      & ${Input}{
        margin-bottom:7px;
      }
      margin: 5px 0;
    display:flex;
    flex-direction: column;
    }
}
@media only screen and (max-width: 970px){
  & ${Button}{
    display: none;
  }
  & ${LoginButton} {
    background-color: #103058;
    border-width:0;
    color:#fff;
  }
}
`

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      invalidPassword: false,
      invalidEmail: false
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    NProgress.start()
    this.setState({
      invalidPassword: false,
      invalidEmail: false
    }, () => {
      this.props.loginGraph(this.email.value, this.password.value, {
        browser: bowser.name,
        name: bowser.osname,
        version: bowser.osversion
      }).then(({ data }) => {
        console.log("graphql data", data)
        if (!data.login.token) {
          NProgress.done()
          console.log("setting state invalid")
          this.setState({
            invalidPassword: true
          })
          return
        }
        this.props.client.writeQuery({
          query: UserQuery,
          data: { user: data.login.user }
        })
        setToken(data.login.token)
        console.log("se escribio query")
        const newState = { ...history.state, time: Date.now() }
        history.pushState(newState, null, location.href)
        Router.replace('/')
      }).catch((error) => {
        console.log('graphql there was an error sending the query', error);
      })
    })


    console.log("bowser", bowser)

  }

  componentDidMount() {
    bowser.detect(navigator.userAgent)
    console.log("shake", shake)
  }

  render() {
    return <LoginContainer>
      <Form isModal={this.props.isModal} onSubmit={this.handleSubmit} >
        <Input invalid={this.state.invalidEmail} placeholder="E-mail" type="email" ref={(ref) => { this.email = ref }} />
        <Input invalid={this.state.invalidPassword} placeholder="Contraseña" type="password" ref={(ref) => { this.password = ref }} />
        <LoginButton style={{ ...this.props.isModal ? { backgroundColor: "#20b0ea" } : null }} type="submit" value="Entrar" />
        <Button style={{ backgroundColor: "rgb(255, 93, 37)", color: "#fff", marginRight: 0 }} type="button" onClick={this.props.onSignupClick}>Pruébalo gratis</Button>
      </Form>
    </LoginContainer >
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

const login_mutation = gql`
  mutation login($email: String, $password: String, $os: OSJson) {
    login(email: $email, password: $password, os: $os){
      user{
        ...userFragment
      }
      token
    }
  }
  ${Userfragments.user}
`

export default compose(withApollo, graphql(login_mutation, {
  props: (props) => {
    const { data, mutate, ownProps } = props
    return {
      loginGraph: (email, password, os) => mutate({ variables: { email: email, password: password, os: os } })
    }
  }
}))(connect(null, mapDispatchToProps)(Login))
