import React from 'react'
import PropTypes from 'prop-types';
import Link from 'next/link'
import styled from 'styled-components'
import Login from '../components/Login'
import Signup, { Titulo } from '../components/Signup'
import Modal from '../utils/modal'
import Head from 'next/head'
import { updateUser } from '../store'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import redirect from "../utils/redirect"
import api from '../utils/api'
import animateScrollTo from 'animated-scroll-to';
import { getTokenFromCookie } from '../utils/auth'
import Router from 'next/router'

import defaultPage from '../hocs/defaultPage'

import config from '../config'

const Header = styled.div`
  min-height: 500px;
  overflow:hidden;
  color: #fff;
  text-align: center;
  height: 100vh;
  max-height:1000px;
  box-sizing: border-box;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  position:relative;
  
    display: grid;
    grid-template-rows: auto 1fr;
`

const HeaderBackground = styled.div`
background-color: #0053d0;
background-size: cover;
position:absolute;
top:0;
right:0;
left:0;
bottom:0;
background-position:center;
background-position-y: top;
`

const Title = styled.img`

`
const Subtitle = styled.div`
  font-weight: bold;
`
const Description = styled.h1`
margin: 0 0 20px 0;
max-width:400px;
text-align:left;
color:#fff;
font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
font-weight:900;
  font-size: 30px;
  letter-spacing: 0;
    line-height: 1.35;
    @media only screen and (max-width: 731px){
      font-size:25px;
      @media only screen and (max-width: 500px){
        font-size:23px;
      }
    }
`

const Box = styled.div`
  display:inline-block;
`

const LoginWrap = styled.div``

const MenuButton = styled.button`
padding: 6px 10px;
font-family: "Open Sans", Roboto, Helvetica;
font-size: 30px;
border-width: 0;
background-color:transparent;
color: #000;
`

const BotonIniciarSesion = styled.button`
border-radius: 3px;
margin: 0 5px;
padding: 6px 10px;
font-family: "Open Sans", Roboto, Helvetica;
font-size: 16px;
color: #fff;
background-color: #20b0ea;
border-width: 0px;
border-color: #d4d4d4;
border-style: solid;
`

const LoginContainer = styled.div`
padding: 14px 70px;
background-color: #fff;
max-width:100vw;
width: 100vw;
box-sizing: border-box;
z-index:3;
display:flex;
justify-content:space-between;
align-items:center;
align-self: flex-start;

& ${MenuButton},${BotonIniciarSesion}{
  display:none;
}

@media only screen and (max-width: 1020px){
  padding: 14px 10px;

  @media only screen and (max-width: 731px){
    position: relative;
  }
}

@media only screen and (max-width: 790px){
  & ${LoginWrap}{
    display: none;
  }
  & ${BotonIniciarSesion} {
    display:block;
  }
  & ${Title}{
    width:200px!important;
  }
  @media only screen and (max-width: 390px){
    & ${BotonIniciarSesion} {
      display:none;
    }
    & ${MenuButton} {
      display:block;
    }
  }
}
`

const Button = styled.button`
  background-color: #20b0ea;
  border-radius: 100px;
  color: #fff;
  border-width: 0px;
  padding: 0 30px;
  line-height: 38px;
  margin-top: 30px;
  font-family: "Open Sans";
`

const Main = styled.div`
  margin: 0 auto;
  text-align: center
`
const Heading = styled.h1`
  font-size: 40px;
  font-weight: 200;
  line-height: 40px
`
const Content = styled.p`
  font-size: 20px;
  font-weight: 200;
  line-height: 30px
`
const ContentLink = styled.a`
  color: #333;
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  transition: border-bottom .2s;
  &:hover {
    border-bottomColor: #333;
  }
`

const GradientBase = styled.div`
position: absolute;
    left: 0;
    width: 100%;
`

const GradientTop = styled(GradientBase)`
top: 0;
    height: 160px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#00000000',GradientType=0 );
`
const GradientBottom = styled(GradientBase)`
bottom: 0;
height: 320px;
background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 100%);
background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#40000000',GradientType=0 )
`
const Gradient = styled(GradientBase)`
background: #0c3961a8;
    height: 100%;
    top: 0;
`

const IphoneXSideContainer = styled.div``
const IphoneDown = styled.div``
const TextContainer = styled.div`
flex: 1;
display:flex;
flex-direction:column;
align-items:flex-start;
`

const DescriptionContainer = styled.div`
height:100%;
margin: 0 auto;
flex-direction: row-reverse;
display: flex;
grid-row-start: 2;
justify-content:center;
align-items:center;

    flex:1;
  & ${Title}{
    margin: 0 auto 20px;
    display: none;
  }

  & ${IphoneDown}{
    display: none;
  }

  & h3{
    text-align:left;
  }

  @media only screen and (max-width: 1020px){
    & ${Title}{
    }
  }

  @media only screen and (max-width: 670px){
    & ${IphoneXSideContainer}{
      display: none;
    }
    & ${TextContainer}{
      align-items:center;
      height:90%;
    }

    & h3{
      text-align:center;
    }

    & ${Description}{
      text-align:center;
      margin-top:0;
    }
    & ${Button}{
      margin-bottom:40px;
    }
    & ${IphoneDown}{
      display: block;
    }
  }
`

const ListContainer = styled.div`
font-family: Roboto;
width: 90%;
margin: 0 auto;
display: flex;
`

const BeneficiosContainer = styled(ListContainer)`
@media only screen and (max-width: 640px){
  flex-direction:column;
}
`


const TiposContainer = styled(ListContainer)`
  justify-content:center;
  flex-wrap: wrap;
  @media only screen and (max-width: 440px){
    flex-wrap: nowrap;
  }
`

const BackMac = styled.div`
padding: 15px 15px;
color:#333333;

`

const BackIphone = styled.div`
padding: 15px 15px 75px;
color:#333333;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+63,d0e3f2+100 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 63%, rgba(208,227,242,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 63%,rgba(208,227,242,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 63%,rgba(208,227,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d0e3f2',GradientType=0 ); /* IE6-9 */
`

const MacContainer = styled(ListContainer)`
flex-direction:row;
justify-content: space-between;
align-items:center;
`

const IconContainer = styled.div`
background-color: #0053d0;
color: #fff;
height: 70px;
width: 70px;
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
margin: 0 auto 20px;
`

const Beneficio = styled.div`
font-size:17px;
line-height: 22px;
padding: 20px 20px;
text-align: center;
flex: 1;
 & i, svg{
  font-size: 35px;
 }
`

const BackBeneficios = styled.div`
padding: 75px 15px;
color:#333333;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d0e3f2+0,ffffff+37,ffffff+100 */
background: rgb(208,227,242); /* Old browsers */
background: -moz-linear-gradient(top, rgba(208,227,242,1) 0%, rgba(255,255,255,1) 37%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(208,227,242,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(208,227,242,1) 0%,rgba(255,255,255,1) 37%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0e3f2', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const TitleBeneficios = styled.h2`
 font-family: Roboto;
 font-weight: 300;
 font-size:40px;
 text-align:center;
 margin-bottom:30px;
 color:#515365;
 @media only screen and (max-width: 915px){
  text-align:center!important;
  font-size:35px;
}
`

const SubtitleBeneficios = styled.h2`
font-family: Lato;
font-weight:700;
font-size:24px;
line-height: 1.5;
    letter-spacing: -.05rem;
    margin-bottom:3px;
`

const BackIdealPara = styled.div`
padding: 75px 15px;
color: #ffffff;
    background-color: #0053d0;
`
const Footer = styled.div`
padding: 75px 15px;
color: #ffffff;
    background-color: #081922;
`

const TipoNegocio = styled.div`
flex:1;
display: flex;
flex-direction:column;
align-items:center;
margin: 20px 20px 0;
font-size:17px;
 & i,svg{
   font-size:35px;
   margin-bottom:15px;
 }

 & div{
   text-align:center;
 }
`

const BackPerspective = styled.div`
padding: 75px 15px;
color:#333333;
`

const Contacto = styled.div`
color:#d2d2d2;
display:flex;
flex-direction: row;
align-items:center;
 & i,svg {
   margin-right: 15px;
   font-size: 23px;
 }
`

const TipoSubcontainer = styled.div`
 display:flex;
 flex-direction:row;
 @media only screen and (max-width: 440px){
  flex-direction: column;
}
`

const PerspectiveImgContainer = styled.div`
  flex:.8;
  @media only screen and (max-width: 915px){
    display: none;
  }
`

const HiddenPerspective = styled.img`
max-width: 100%;
    width: 400px;
    margin: 30px auto 30px;
    display:none;
    @media only screen and (max-width: 915px){
      display: block;
    }
`

const PerspectiveInfoContainer = styled.div`
margin-top: 4%; 
align-self: flex-start;
margin-left:40px; 
flex:1;
@media only screen and (max-width: 915px){
  margin:0;
}
`

const InfoMacContainer = styled.div`
margin-bottom:80px;
margin-right:60px;
@media only screen and (max-width: 915px){
  margin:0;
}
`

const BadgeImage = styled.img`
@media only screen and (max-width: 915px){
  width:100%;
}
`

const InfoIphoneContainer = styled.div`
margin-bottom: 80px;
margin-left:20px;
flex:1;
@media only screen and (max-width: 915px){
  flex:1;
  margin-top:40px;
  margin-bottom:30px;
  margin-left:0;
}
`

const TitleFormaParte = styled(TitleBeneficios)`
@media only screen and (max-width: 915px){
  font-size:22px!important;
}
`

const NegocioH2 = styled.h2`
  font-weight:400;
  font-size:17px;
  text-align:center;
`

const BeneficioH3 = styled.h3`
font-weight:400;
  font-size:17px;
`

class WelcomePage extends React.Component {

  static async getInitialProps(ctx, apolloClient, isAuthenticated) {
    console.log("getInitialProps WelcomePage")
    const pago = ctx.query.pago == ""
    const tokenUnparsed = pago && !process.browser && getTokenFromCookie(ctx.req, "tempToken")
    if (isAuthenticated)
      redirect(ctx, "/", { type: "replace" })
    else {
      api.token = null
      console.log("resseting cache apollo")
      apolloClient.cache.reset();
    }
    return {
      campaign: ctx.query.utm_campaign,
      registro: ctx.query.registro,
      pago,
      tempToken: tokenUnparsed && JSON.parse(decodeURIComponent(tokenUnparsed)) || null
    }
  }

  constructor(props) {
    console.log("constructor welcomepage")
    super(props)
    this.state = {
      modalIsOpen: props.registro ? true : false,
    }
    this.openModalRegistro = this.openModalRegistro.bind(this);
    this.closeModalRegistro = this.closeModalRegistro.bind(this);
  }

  componentWillMount() {
    console.log("component will mount welcomepage")
  }

  componentDidMount() {
    console.log("component did mount welcomepage")
    this.setState({
      pageLoaded: true
    })
    if (!document.getElementById('crisp-chat-script')) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "fd03c2ce-9f4d-4a1c-a417-eaa6caaa5ca6";
      (function () {
        let d = document;
        let s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.setAttribute("id", "crisp-chat-script");
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      }
      )();
    } else {
      window.$crisp.push(['do', 'chat:show']);
    }
  }

  componentWillUnmount() {
    console.log("component will unmount welcomepage")
    window.$crisp.push(['do', 'chat:hide']);
  }

  openModalRegistro() {
    Router.push('/registro')
  }

  closeModalRegistro() {
    this.setState({ modalIsOpen: false });
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  toggleModalLogin = () => {
    this.setState({
      modalLogin: !this.state.modalLogin
    })
  }

  render() {
    console.log("render WelcomePage", this.state.modalIsOpen)
    return (<div>
      <Head>
        <title>Aggendi | Automatización y control de citas</title>
        <meta name="description" content="Despreocúpate por tus citas y haz felices a tus clientes. Lleva el control de tu agenda de citas desde tu celular, ten tu asistente virtual 24 hrs tomando citas. Permite a tus clientes reservar por Facebok, Instagram o sitio web. Ideal para consultorios, spa's, salones de belleza, oficinas, etc."></meta>
      </Head>
      <Modal
        immediate={this.props.registro}
        visible={this.state.modalIsOpen}
        onClose={this.closeModalRegistro}
      >
        <Signup tempToken={this.props.tempToken} pago={this.props.pago} />
      </Modal>
      <Modal
        visible={this.state.modalLogin}
        onClose={this.toggleModalLogin}
      >
        <Titulo>Iniciar sesión</Titulo>
        <Login isModal={true} />
      </Modal>
      <Header>
        <HeaderBackground />
        <LoginContainer>
          <div style={{ marginRight: 30 }}><Title style={{ marginTop: 7, marginBottom: 5, width: 230, maxWidth: "100%" }} src={config.api + "/static/logo_black.png"} alt="" /></div>
          <LoginWrap><Login onSignupClick={this.openModalRegistro} /></LoginWrap>
          <BotonIniciarSesion onClick={this.toggleModalLogin}>
            Iniciar sesión
            </BotonIniciarSesion>
          <MenuButton onClick={this.toggleMenu} ><i className="fas fa-bars"></i></MenuButton>
        </LoginContainer>
        <div style={{ width: "100%", height: "100%", maxHeight:842, padding: "30px 5%", boxSizing: "border-box", zIndex: 2, position: "relative" }}>
          {this.state.showMenu && <div style={{ width: "100%", zIndex: 3, backgroundColor: "#fff", position: "absolute", boxSizing: "border-box", top: 0, left: 0, padding: "10px 20px", borderTop: "1px solid #e6e6e6" }}>
            <BotonIniciarSesion style={{ width: "100%", margin: 0 }} onClick={this.toggleModalLogin}>
              Iniciar sesión
            </BotonIniciarSesion>
          </div>}
          <DescriptionContainer style={{ maxWidth: 960, width: "100%" }}>
            <IphoneXSideContainer style={{ flex: 1, maxWidth: 400, position: "relative", marginLeft: 20, alignSelf: "stretch" }}>
              <img style={{ width: "100%", maxWidth: "100%", position: "absolute", left: 0, top: 0 }} src={config.api + "/static/iphoneX.png"} alt="" />
            </IphoneXSideContainer>
            <TextContainer>
              <Description>Despreocúpate por tus citas y haz felices a tus {this.props.campaign == "dentistas" ? "pacientes" : "clientes"}</Description>
              <h3 style={{ fontSize: 17, lineHeight: "25px", fontWeight: 200 }} >Automatización y control de citas. Extremadamente fácil de usar.</h3>
              <Button style={{ backgroundColor: "#29317a", position: "relative" }} type="button" onClick={this.openModalRegistro} >
                <span style={{ ...this.state.pageLoaded ? null : { visibility: "hidden" } }} >Inicia prueba de 30 días gratis</span>
                <div style={{ ...this.state.pageLoaded ? { display: "none" } : { display: "flex" }, position: "absolute", top: 0, right: 0, left: 0, bottom: 0, justifyContent: "center", alignItems: "center" }}><i style={{ color: "#fff" }} className="fa fa-spinner fa-spin fa-1x fa-fw"></i></div>
              </Button>
              <IphoneDown style={{ position: "relative", width: "100%", maxWidth: 350 }}>
                <div style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}><img style={{ maxWidth: "100%" }} src={config.api + "/static/iphoneX.png"} alt="" /></div>
              </IphoneDown>
            </TextContainer>
          </DescriptionContainer>
        </div>
      </Header>


      <BackPerspective>
        <MacContainer style={{ justifyContent: "space-around" }} >
          <PerspectiveImgContainer ><img style={{ width: "100%", maxWidth: 583 }} src={config.api + "/static/perspectiveClient.png"} alt="" /></PerspectiveImgContainer>
          <PerspectiveInfoContainer>
            <TitleBeneficios style={{ textAlign: "left", marginBottom: 20 }} >Tu asistente virtual tomando citas</TitleBeneficios>
            <HiddenPerspective src={config.api + "/static/perspectiveClient.png"} alt="" />
            <h3 style={{ fontSize: 17, lineHeight: "25px", fontWeight: 400 }} >Te damos <span style={{ fontWeight: "bold" }}>tu propio sitio de reservación</span> con una direccion personalizada que puedes compartir con tus clientes y en redes sociales.
            <div style={{ marginTop: 20 }}><span>Recibe citas en linea y</span> despreocupate cuando no hay nadie que conteste el telefono o los mensajes, deja que Aggendi trabaje por ti.</div>
            </h3>
          </PerspectiveInfoContainer>
        </MacContainer>
      </BackPerspective>

      <BackIdealPara id="ideal" >
        <TitleBeneficios style={{ color: "#fff" }} >Perfecto para tu negocio</TitleBeneficios>
        <TiposContainer>
          <TipoSubcontainer>
            <TipoNegocio>
              <i className="fas fa-user-md"></i>
              <NegocioH2>Consultorios</NegocioH2>
            </TipoNegocio>
            <TipoNegocio>
              <i className="fas fa-spa"></i>
              <NegocioH2>Spa's</NegocioH2>
            </TipoNegocio>
            <TipoNegocio>
              <i className="fas fa-cut"></i>
              <NegocioH2>Salones de belleza</NegocioH2>
            </TipoNegocio>
          </TipoSubcontainer>
          <TipoSubcontainer>
            <TipoNegocio>
              <i className="fas fa-building"></i>
              <NegocioH2>Oficinas</NegocioH2>
            </TipoNegocio>
            <TipoNegocio>
              <i className="fas fa-utensils"></i>
              <NegocioH2>Restaurant's</NegocioH2>
            </TipoNegocio>
            <TipoNegocio>
              <i className="fas fa-plus"></i>
              <div>y muchos mas...</div>
            </TipoNegocio>
          </TipoSubcontainer>
        </TiposContainer>
      </BackIdealPara>

      <BackBeneficios>
        <TitleBeneficios>¿Cuales son los beneficios?</TitleBeneficios>
        <BeneficiosContainer>
          <Beneficio>
            <IconContainer><i className="fas fa-bolt"></i></IconContainer>
            <SubtitleBeneficios>Eficientiza</SubtitleBeneficios>
            <BeneficioH3>Tengas secretaria o no, mantente coordinado y ahorra tiempo organizando tus citas</BeneficioH3>
          </Beneficio>
          <Beneficio>
            <IconContainer><i className="fas fa-robot"></i></IconContainer>
            <SubtitleBeneficios>Automatiza</SubtitleBeneficios>
            <BeneficioH3>Notificaciones y recordatorios automaticos para ti y tus clientes</BeneficioH3>
          </Beneficio>
          <Beneficio>
            <IconContainer><i className="fas fa-globe-americas"></i></IconContainer>
            <SubtitleBeneficios>Moderniza</SubtitleBeneficios>
            <BeneficioH3>Brinda un mejor servicio y moderniza tu negocio recibiendo citas en linea</BeneficioH3>
          </Beneficio>
        </BeneficiosContainer>
      </BackBeneficios>
      <BackMac>
        <MacContainer>
          <InfoMacContainer>
            <TitleBeneficios style={{ textAlign: "left", marginBottom: 20 }} >Amigable y fácil de usar</TitleBeneficios>
            <HiddenPerspective src={config.api + "/static/mockupMac.png"} alt="" />
            <h3 style={{ fontSize: 17, lineHeight: "25px", fontWeight: 400 }} >Configura tu calendario segun tus horarios, enlista tus distintos servicios y administra tus citas de la manera mas sencilla</h3>
          </InfoMacContainer>
          <PerspectiveImgContainer><img height="400px" src={config.api + "/static/mockupMac.png"} alt="" /></PerspectiveImgContainer>
        </MacContainer>
      </BackMac>
      <BackIphone>
        <MacContainer style={{ justifyContent: "space-around" }} >
          <PerspectiveImgContainer style={{ flex: "none", textAlign: "center" }} ><img style={{ paddingRight: 30, paddingLeft: 30 }} height="400px" src={config.api + "/static/iphoneX.png"} alt="" /></PerspectiveImgContainer>
          <InfoIphoneContainer>
            <TitleBeneficios style={{ textAlign: "left", marginBottom: 20 }} >Con aplicación móvil</TitleBeneficios>
            <HiddenPerspective style={{ height: 350, width: "auto" }} src={config.api + "/static/iphoneX.png"} alt="" />
            <h3 style={{ fontSize: 17, fontWeight: 400, lineHeight: "25px" }} >Lleva control de tus citas o reservaciones desde tu celular a cualquier hora y en cualquier lugar, recibe notificaciones y recordatorios automaticos</h3>
            <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
              <div style={{ height: 56 }} ><a target="_blank" href="https://itunes.apple.com/mx/app/agenda-vip-admin/id1292343149?l=en&mt=8"><BadgeImage style={{ height: "100%", objectFit: "contain", marginRight: 15 }} src={config.api + "/static/appleBadge.png"} alt="" /></a></div>
              <div style={{ height: 56 }}><a target="_blank" href="https://play.google.com/store/apps/details?id=com.appagendameadmin"><BadgeImage style={{ height: "100%", objectFit: "contain" }} src={config.api + "/static/googleBadge.png"} alt="" /></a></div>
            </div>
          </InfoIphoneContainer>
        </MacContainer>
      </BackIphone>
    
      <BackIdealPara>
        <TitleBeneficios style={{ color: "#fff" }} >Promoción por tiempo limitado
        </TitleBeneficios>
        <div style={{ textAlign: "center", fontSize: 20 }} ><span style={{ fontSize: 30 }} >$199 MXN</span> / mensuales
        <div><Button style={{ fontSize: 16, backgroundColor: "#29317a" }} type="button" onClick={this.openModalRegistro} >Inicia prueba de 30 días gratis</Button></div>
        </div>
      </BackIdealPara>
      <Footer>
        <TitleFormaParte style={{ fontSize: 26, color: "#fff", width: 600, maxWidth: "100%", margin: "0 auto 50px" }} >¡Forma parte de los negocios que ya aceptan citas y reservaciones en linea!</TitleFormaParte>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "90%", margin: "0 auto", alignItems: "flex-end", flexWrap: "wrap" }} >
          <div style={{ fontSize: 18 }} >
            <div style={{ fontWeight: "bold", fontSize: 24, marginBottom: 20 }} >Contacto</div>
            <Contacto style={{ marginBottom: 10 }} ><i className="fas fa-envelope"></i> info@agenda.vip</Contacto>
            <Contacto><i className="fas fa-phone"></i> (33) 11962844</Contacto>
          </div>
          <div>
            <img src={config.api + "/static/appDinamic_white.png"} alt="" />
          </div>
        </div>
      </Footer>
    </div>)
  }

}

WelcomePage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: bindActionCreators(updateUser, dispatch)
  }
}

export default defaultPage(connect(null, mapDispatchToProps)(WelcomePage))
